
















































import {Component} from 'vue-property-decorator';
import {dispatchGetAccessCodes} from '@/store/crud/actions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {IAccessCode} from '@/interfaces/accessCode';
import {copyTextToClipboard} from '@/utils/copyToClipboard';

@Component({
  methods: {copyTextToClipboard},
})
export default class AccessCodesList extends AppComponent {
  public headers = [
    {
      text: 'Access Code',
      sortable: true,
      value: 'access_code',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Patient ID',
      sortable: true,
      value: 'patient_id',
      align: 'left',
    },
    {
      text: 'First name',
      sortable: true,
      value: 'fname',
      align: 'left',
    },
    {
      text: 'Last name',
      sortable: true,
      value: 'lname',
      align: 'left',
    },
  ];

  public search = '';
  public fetchingAccessCodes = false;
  public selectedAccessCode: IAccessCode | null = null;

  public get isFetchingCode() {
    return !!this.$router.currentRoute.params.id && !this.selectedAccessCode;
  }

  public editHandler(accessCode: IAccessCode) {
    this.selectedAccessCode = accessCode;
    this.$router.push({name: 'main-admin-accesscode-edit', params: {id: accessCode.id}});
  }

  public get accessCodes() {
    return this.$store.state.crud.accessCodes;
  }

  public async fetchData() {
    this.fetchingAccessCodes = true;
    await this.$nextTick();
    await dispatchGetAccessCodes(this.$store);
    this.fetchingAccessCodes = false;
    if (this.isFetchingCode) {
      const selectedAccessCode = this.accessCodes.find(
        (ac) => ac.id === Number(this.$router.currentRoute.params.id),
      );
      if (selectedAccessCode) {
        this.selectedAccessCode = selectedAccessCode;
      } else {
        await this.$router.push({name: 'main-admin-accesscode-list'});
      }
    }
  }

  public mounted() {
    this.fetchData();
  }
}
